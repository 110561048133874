import React from "react";
import { Box, Flex, chakra, IconButton } from "@chakra-ui/react";
import { FiMapPin } from "react-icons/fi";

import { EventTimeString, EventLocationTitle } from "../helpers/strings";
import colors from "../helpers/colors";
import CopyButton from "./CopyButton";
import EventReply from "./EventReply";
import EventInviteCardHeader from "./EventInviteCardHeader";
import OnboardUser from "./OnboardUser";

const EventInviteCardFull = ({ eventInvite, currentUser }) => {
  const currentUserOnboarded = currentUser?.onboarded;

  const renderBody = () => {
    if (!currentUserOnboarded) {
      return null;
    }

    return (
      <>
        {eventInvite?.eventInfo?.description && (
          <chakra.p
            fontSize="sm"
            color={colors.ink}
            style={{
              marginTop: 5,
              paddingBottom: 20,
              fontWeight: 300,
              whiteSpace: "pre-wrap",
              maxHeight: 300,
              overflowY: "scroll",
            }}
          >
            {eventInvite?.eventInfo?.description}
          </chakra.p>
        )}

        {eventInvite?.eventInfo?.location && (
          <Flex mt={5} alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" style={{ paddingRight: 30 }}>
              <chakra.p fontSize="sm" color={colors.ink}>
                <span style={{ fontSize: 16, fontWeight: 500 }}>
                  {EventLocationTitle(
                    eventInvite?.eventInfo?.locationCustomName,
                    eventInvite?.eventInfo?.location
                  )}
                </span>
                <br />
                <span style={{ color: colors.greyDark }}>
                  {eventInvite?.eventInfo?.location?.formattedAddress}
                </span>
              </chakra.p>
            </Flex>

            <Flex alignItems="center" style={{ fontWeight: 500, fontSize: 14 }}>
              <CopyButton
                textToCopy={eventInvite?.eventInfo?.location?.formattedAddress}
              />

              <IconButton
                variant="outline"
                color={colors.electricBlue}
                style={{ marginLeft: 10 }}
                onClick={() => {
                  window.open(
                    "https://www.google.com/maps/search/?api=1&query=" +
                      encodeURI(
                        eventInvite?.eventInfo?.location?.formattedAddress
                      ),
                    "_blank"
                  );
                }}
                icon={<FiMapPin size="1.3em" />}
              />
            </Flex>
          </Flex>
        )}

        <Box mt={8} mb={2}>
          <EventReply currentUser={currentUser} eventInvite={eventInvite} />
        </Box>
      </>
    );
  };

  return (
    <Flex p={4} w="full" alignItems="center" justifyContent="center">
      <Box mx="auto" rounded="lg" shadow="md" bg="white" w="full" maxW="lg">
        <EventInviteCardHeader
          title={eventInvite?.eventInfo?.title}
          coverImageUrl={eventInvite?.eventInfo?.coverImageUrl}
          leftText={EventTimeString(
            eventInvite?.eventInfo?.startDate,
            eventInvite?.eventInfo?.startTime
          )}
          hostName={eventInvite?.eventInfo?.hostName}
          coHostName={eventInvite?.eventInfo?.coHostName}
          guestList={eventInvite?.eventInfo?.eventInfoGuests}
        />

        <Box p={6}>
          <OnboardUser eventInvite={eventInvite} currentUser={currentUser} />
          {renderBody()}
        </Box>
      </Box>
    </Flex>
  );
};

export default EventInviteCardFull;
