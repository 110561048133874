import React from "react";

import PublicPageContainer from "./PublicPageContainer";
import AuthForm from "../components/AuthForm";

function AuthPage() {
  return (
    <PublicPageContainer>
      <AuthForm />
    </PublicPageContainer>
  );
}

export default AuthPage;
