import React from "react";
import { Flex } from "@chakra-ui/react";

import EventInviteReplyButtons from "./EventInviteReplyButtons";
import EventExportCalendarButton from "./EventExportCalendarButton";

export default function EventReply({ eventInvite, currentUser }) {
  return (
    <Flex alignItems="center" flex={1} flexDirection="column">
      <Flex alignItems="center" mb={3} width="100%">
        <EventInviteReplyButtons
          eventInviteId={eventInvite?.id}
          replyStatus={eventInvite?.replyStatus}
          hostName={eventInvite?.eventInfo?.hostName}
          coHostName={eventInvite?.eventInfo?.coHostName}
        />
      </Flex>
      <Flex alignItems="center" width="100%">
        <EventExportCalendarButton eventInvite={eventInvite} />
      </Flex>
    </Flex>
  );
}
