const colors = {
  none: null,
  black: "#000",
  white: "#fff",
  greyDark: "#8C8B9A",
  greyMedium: "#AAAAAA",
  greyLight: "#efefef",
  greyFeatherLight: "#FBFBFB",
  modalBackdrop: "rgba(0, 0, 0, 0.7)",
  titleColorShadow: "rgba(0, 0, 0, 0.15)",
  transparent: "rgba(0, 0, 0, 0.0)",
  ink: "#222B45",
  electricBlue: "#3366FF",
  lavalicous: "#FB5864",
  lava: "#FF4B40",
  red: "#ff0033",
  // Eva colors
  success: "#51DD44",
  info: "#8BF1FF",
  warning: "#FFC670",
  danger: "#281284",
};

export default colors;
