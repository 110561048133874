import React from "react";
import { Box } from "@chakra-ui/react";

import Header from "../components/Header";

function PublicPageContainer({ children }) {
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      background="linear-gradient(134.93deg, #FFC670 -5.44%, #FF4B40 48.38%)"
    >
      <Header />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex={1}
      >
        {children}
      </Box>
    </Box>
  );
}

export default PublicPageContainer;
