import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

import { IsAuthd, REDIRECT_AFTER_LOGIN_KEY } from "../services/ShakaAuth";
import ShakaLocalStorage from "../services/ShakaLocalStorage";

export default function PrivateRoute(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  let { eventId } = useParams();

  // Check auth state on component mount
  useEffect(() => {
    const fetchData = async () => {
      const isauthd = await IsAuthd();

      if (!isauthd && !!eventId) {
        // Handle redirect after login
        ShakaLocalStorage.save({
          key: REDIRECT_AFTER_LOGIN_KEY,
          data: {
            redirectPath: "/e/" + eventId,
          },
        });
      }

      setIsLoggedIn(isauthd);
    };
    fetchData();
  });

  if (isLoggedIn == null) {
    return null;
  } else if (isLoggedIn) {
    return <Outlet />;
  } else if (!!eventId) {
    return (
      <Navigate
        to={{
          pathname: "/ep/" + eventId,
        }}
      />
    );
  } else {
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
      />
    );
  }
}
