import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
} from "@chakra-ui/react";

import colors from "../helpers/colors";

const CopyButton = ({ triggerComponent, popoverText }) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const handleClick = () => {
    setPopoverOpen(true);

    setTimeout(() => {
      setPopoverOpen(false);
    }, 1500);
  };

  return (
    <Popover placement="top" isOpen={popoverOpen}>
      <PopoverTrigger>
        <div onClick={handleClick}>{triggerComponent}</div>
      </PopoverTrigger>
      <PopoverContent
        width="auto"
        bg={colors.electricBlue}
        color={colors.white}
        borderColor={colors.electricBlue}
      >
        <PopoverHeader style={{ borderBottom: 0 }}>{popoverText}</PopoverHeader>
      </PopoverContent>
    </Popover>
  );
};

export default CopyButton;
