import React from "react";
import { IconButton } from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";

import colors from "../helpers/colors";
import AutoPopover from "./AutoPopover";

const CopyButton = ({ textToCopy }) => {
  const button = (
    <IconButton
      variant="outline"
      color={colors.electricBlue}
      style={{ marginLeft: 10 }}
      onClick={() => {
        navigator.clipboard.writeText(textToCopy);
      }}
      icon={<FiCopy size="1.3em" />}
    />
  );

  return (
    <AutoPopover popoverText="address copied!" triggerComponent={button} />
  );
};

export default CopyButton;
