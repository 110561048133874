import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";

import {
  APP_NAME,
  APP_VERSION,
  IsProduction,
  ProductionApiUrl,
  DevelopmentApiUrl,
} from "../helpers/config";
// eslint-disable-next-line import/no-cycle
import { GetLocalAuthToken, Logout } from "./ShakaAuth";

const httpLink = createUploadLink({
  uri: IsProduction() ? ProductionApiUrl : DevelopmentApiUrl,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await GetLocalAuthToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ networkError }, cb) => {
  if (
    networkError &&
    (networkError.statusCode === 401 || networkError.statusCode === 403)
  ) {
    // remove cached token on 401 from the server
    Logout();
    // pass something back to apollo
    cb({ data: null });
  }
});

const ShakaApolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  name: APP_NAME,
  version: APP_VERSION,
  cache: new InMemoryCache(),
  fetchPolicy: "network-only",
});

export default ShakaApolloClient;
