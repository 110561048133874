import React from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import colors from "../helpers/colors";

export default function PhoneNumberInput({ onPhoneNumberChange }) {
  return (
    <IntlTelInput
      fieldName="tel"
      fieldId="tel"
      defaultCountry="us"
      onPhoneNumberChange={onPhoneNumberChange}
      placeholder="1234567890"
      autoFocus={true}
      autoComplete="on"
      style={{
        backgroundColor: colors.white,
        fontSize: 18,
        fontWeight: 400,
        height: 54,
        letterSpacing: 0.5,
        borderRadius: 4,
        display: "flex",
      }}
      telInputProps={{
        style: {
          backgroundColor: colors.transparent,
          border: "none",
        },
      }}
    />
  );
}
