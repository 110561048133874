import React from "react";
import { Flex, chakra } from "@chakra-ui/react";

import colors from "../helpers/colors";

const WaitlistFooter = () => {
  return (
    <Flex
      marginTop={5}
      marginBottom={5}
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        maxW="md"
        mx="auto"
        bg="white"
        shadow="lg"
        rounded="lg"
        overflow="hidden"
        style={{
          minWidth: 360,
          transition: "all 300ms ease-out",
          alignItems: "center",
          justifyContent: "center",
        }}
        _hover={{
          transform: "scale(1.05)",
          transition: "all .2s ease-in-out",
          textAlign: "center",
        }}
        px={5}
        py={4}
      >
        <a target="_blank" href="https://heyshaka.com" rel="noreferrer">
          <chakra.h1
            fontSize="md"
            fontWeight="md"
            color={colors.ink}
            textAlign="center"
          >
            want to send your own vites?
            <br />
            join the waitlist at heyshaka.com
          </chakra.h1>
        </a>
      </Flex>
    </Flex>
  );
};

export default WaitlistFooter;
