/* eslint-disable import/prefer-default-export */

import moment from "moment";
import colors from "./colors";

export const LoginErrorMsg =
  "There was an error logging in, please check your phone number and try again.";

export const LoginCodeErrorMsg =
  "There was an error logging in, please check your verification code and try again.";

export const EventTimeString = (startDate, startTime) => {
  if (!startDate || startDate === "") {
    return null;
  }

  let eventTimeString = formatStartDateDisplay(startDate);

  if (startTime) {
    eventTimeString += `, ${moment(startTime, "HH:mm:ssZ").format("h:mm A")}`;
  }

  return eventTimeString;
};

export const formatStartDateDisplay = (date) => {
  if (!date) {
    return "";
  }

  const startMoment = moment(date);

  if (startMoment.isSame(moment(), "day")) {
    return "today";
  }
  if (startMoment.isSame(moment().add(1, "day"), "day")) {
    return "tomorrow";
  }
  if (startMoment.year() !== moment().year()) {
    return startMoment.format("dddd, MMM D, YYYY");
  }

  return startMoment.format("dddd, MMM DD");
};

export const ReplyStatusString = (replyStatus) => {
  switch (replyStatus) {
    case "yes":
      return "going";
    case "no":
      return "not going";
    case "maybe":
      return "maybe";
    default:
      return "new";
  }
};

export const ReplyStatusColor = (replyStatus) => {
  switch (replyStatus) {
    case "yes":
      return colors.success;
    case "no":
      return colors.danger;
    case "maybe":
      return colors.warning;
    default:
      return colors.lava;
  }
};

export const EventLocationTitle = (locationCustomName, location) => {
  return `${locationCustomName || location?.name}`;
};

export const EventHostNamesString = (hostName, coHostName) => {
  if (!hostName || hostName === "") {
    return "";
  }
  return `${hostName}${coHostName ? ` & ${coHostName}` : ""} invited you`;
};
