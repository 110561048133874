import React from "react";
import { Box, Flex, Text, Wrap, WrapItem } from "@chakra-ui/react";

import colors from "../helpers/colors";

const EventInviteCardHeaderGuestList = ({ guestList }) => {
  const [activeCategory, setActiveCategory] = React.useState("yes");

  if (!guestList || guestList.length === 0) return null;

  const maybeGuests = guestList.filter(
    (guest) => guest?.replyStatus === "maybe"
  );
  const yesGuests = guestList.filter((guest) => guest?.replyStatus === "yes");

  let activeCategoryGuests = [];
  if (activeCategory === "yes") {
    activeCategoryGuests = yesGuests;
  } else if (activeCategory === "maybe") {
    activeCategoryGuests = maybeGuests;
  }

  const buttonStyle = {
    cursor: "pointer",
    textAlign: "center",

    marginRight: 5,

    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 5,

    fontWeight: 500,
    fontSize: 14,
  };

  const activeButtonStyle = {
    ...buttonStyle,

    borderBottomColor: colors.lava,
    borderBottomWidth: 3,
  };

  return (
    <Box>
      <div
        fontSize="md"
        color={colors.ink}
        style={{
          borderBottom: `1px solid ${colors.greyLight}`,
          paddingTop: 22,
          paddingBottom: 20,
          fontWeight: 600,
          paddingLeft: 23,
          paddingRight: 23,
        }}
      >
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Flex style={{ fontWeight: 500, fontSize: 14 }} flex={1} mb={4}>
            {yesGuests?.length > 0 && (
              <div
                onClick={() => setActiveCategory("yes")}
                style={
                  activeCategory === "yes" ? activeButtonStyle : buttonStyle
                }
              >
                <Text>{yesGuests?.length} going 👍</Text>
              </div>
            )}
            {maybeGuests?.length > 0 && (
              <div
                onClick={() => setActiveCategory("maybe")}
                style={
                  activeCategory === "maybe" ? activeButtonStyle : buttonStyle
                }
              >
                <Text>{maybeGuests?.length} maybe 🤔</Text>
              </div>
            )}
          </Flex>
          <Flex
            alignItems="center"
            style={{ fontWeight: 500, fontSize: 14 }}
            flex={1}
          >
            {activeCategoryGuests?.length > 0 && (
              <Wrap>
                {activeCategoryGuests?.map((guest) => {
                  return (
                    <WrapItem>
                      <div
                        style={{
                          backgroundColor: colors.lava,
                          fontSize: 11,
                          fontWeight: 500,
                          color: colors.white,

                          borderRadius: 14,

                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 4,
                          paddingBottom: 4,
                        }}
                      >
                        {guest?.name}
                      </div>
                    </WrapItem>
                  );
                })}
              </Wrap>
            )}
          </Flex>
        </Flex>
      </div>
    </Box>
  );
};

export default EventInviteCardHeaderGuestList;
