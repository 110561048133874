import React from "react";
import { Button } from "@chakra-ui/react";
import { atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";
import moment from "moment-timezone";

import {
  EVENT_INVITE_REPLY_STATUS_NO_REPLY,
  EVENT_INVITE_REPLY_STATUS_NO,
  timeFormat,
} from "../graphql/event_invites";
import colors from "../helpers/colors";

export default function EventInviteReplyButtons({ eventInvite }) {
  if (
    !eventInvite.replyStatus ||
    eventInvite.replyStatus === EVENT_INVITE_REPLY_STATUS_NO_REPLY ||
    eventInvite.replyStatus === EVENT_INVITE_REPLY_STATUS_NO
  ) {
    return null;
  }

  const handlClick = (status) => {
    atcb_action({
      name: eventInvite.eventInfo?.title,
      description:
        (eventInvite?.eventInfo?.description || "") +
        "\n\n" +
        eventInvite?.eventInfo?.shortUrl,
      location:
        eventInvite?.eventInfo?.location?.formattedAddress ||
        eventInvite?.eventInfo?.location?.locationCustomName ||
        null,

      startDate: eventInvite?.eventInfo?.startDate,
      endDate: eventInvite?.eventInfo?.endDate,
      startTime: eventInvite?.eventInfo?.startTime
        ? moment(eventInvite?.eventInfo?.startTime, timeFormat).format("HH:mm")
        : null,
      endTime:
        eventInvite?.eventInfo?.endTime || eventInvite?.eventInfo?.startTime
          ? moment(
              eventInvite?.eventInfo?.startTime ||
                eventInvite?.eventInfo?.startTime,
              timeFormat
            ).format("HH:mm")
          : null,
      timeZone: moment.tz.guess() || null,

      options: ["Apple", "Google", "iCal"],
      iCalFileName: eventInvite.eventInfo?.title,
    });
  };

  return (
    <Button
      size="md"
      variant="outline"
      style={{
        flex: 1,
        borderColor: colors.electricBlue,
        color: colors.electricBlue,
      }}
      onClick={handlClick}
    >
      add to calendar
    </Button>
  );
}
