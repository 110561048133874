import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Wrap,
  WrapItem,
  Avatar,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import _ from "lodash";

import colors from "../helpers/colors";
import { getEventMetaTitle, coverDefault } from "../helpers/metadata";
import { EventHostNamesString } from "../helpers/strings";
import EventInviteCardHeaderGuestList from "./EventInviteCardHeaderGuestList";

const EventInviteCardHeader = ({
  title,
  coverImageUrl,
  leftText,
  hostName,
  coHostName,
  guestList,
}) => {
  const metaTitle = getEventMetaTitle(title, hostName, coHostName);
  const imgUrl = coverImageUrl ? coverImageUrl : coverDefault;

  return (
    <Box>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <Box
        roundedTop="lg"
        w="full"
        h={320}
        style={{
          position: "relative",
        }}
      >
        <Text
          style={{
            position: "absolute",
            zIndex: 4,
            bottom: 10,
            left: 17,
            color: colors.white,
            fontSize: 42,
            fontWeight: "900",
            textShadowColor: colors.titleColorShadow,
            textShadowOffset: { width: 0, height: 0 },
            textShadowRadius: 16,
          }}
        >
          {title}
        </Text>

        <div
          style={{
            position: "absolute",
            zIndex: 1,
            flex: 1,
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(to bottom, rgba(40, 18, 132, 0), rgba(40, 18, 132, 0), rgba(40, 18, 132, .4))",
          }}
        />

        <Image roundedTop="lg" w="full" h={320} fit="cover" src={imgUrl} />
      </Box>

      <Box>
        <div
          fontSize="md"
          color={colors.ink}
          style={{
            borderBottom: `1px solid ${colors.greyLight}`,
            paddingTop: 22,
            paddingBottom: 20,
            fontWeight: 600,
            paddingLeft: 23,
            paddingRight: 23,
          }}
        >
          <Flex
            alignItems="flex-start"
            flexDirection={"column"}
            justifyContent={"center"}
          >
            {leftText && <Flex style={{ marginBottom: 5 }}>{leftText}</Flex>}

            <Flex
              alignItems="flex-start"
              style={{ fontWeight: 400, fontSize: 14 }}
            >
              {EventHostNamesString(hostName, coHostName, "invited you")}
            </Flex>
          </Flex>
        </div>
      </Box>

      <EventInviteCardHeaderGuestList guestList={guestList} />
    </Box>
  );
};

export default EventInviteCardHeader;
