import React from "react";
import { Box, Button, Input } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import {
  REDIRECT_AFTER_LOGIN_KEY,
  VerifySmsCheck,
  VerifySmsStart,
} from "../services/ShakaAuth";
import PhoneNumberInput from "./PhoneNumberInput";
import colors from "../helpers/colors";
import ShakaLocalStorage from "../services/ShakaLocalStorage";

export default function AuthForm() {
  const [isLoading, setIsLoading] = React.useState(false);

  const [phoneValue, setPhoneValue] = React.useState("");
  const [phoneValid, setPhoneValid] = React.useState(false);

  const [showCodeInput, setShowCodeInput] = React.useState(false);
  const [code, setCode] = React.useState("");

  let navigate = useNavigate();

  const onPhoneNumberChange = (
    isValid,
    value,
    selectedCountryData,
    fullNumber,
    extension
  ) => {
    const cleanedNumber = fullNumber.replace(/[-\s()]/g, "");
    setPhoneValue(cleanedNumber);
    setPhoneValid(isValid);
  };

  const onStartVerifySmsButtonPress = () => {
    if (!phoneValid) {
      alert("Please check your phone number, this one's invalid.");

      return;
    }

    setIsLoading(true);

    VerifySmsStart({ phoneNumber: phoneValue })
      .then(() => {
        setShowCodeInput(true);
      })
      .catch((e) => {
        alert(JSON.stringify(e));
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onVerifySmsCheckButtonPress = async () => {
    if (code === "") {
      return;
    }

    setIsLoading(true);

    await VerifySmsCheck({ phoneNumber: phoneValue, code })
      .then(async () => {
        await ShakaLocalStorage.load({
          key: REDIRECT_AFTER_LOGIN_KEY,
        })
          .then((ret) => {
            // Handle redirect after login
            if (!ret || !ret?.redirectPath) {
              navigate(`/`);
            } else {
              ShakaLocalStorage.remove({
                key: REDIRECT_AFTER_LOGIN_KEY,
              });
              navigate(ret.redirectPath);
            }
          })
          .catch(() => {
            navigate(`/`);
          });
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert("Login Error", e?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box
      maxW="600px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex={0.5}
    >
      {showCodeInput ? (
        <Input
          type="number"
          placeholder={"code sent to " + phoneValue}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          variant="filled"
          style={{ backgroundColor: "white", textAlign: "center" }}
          size="lg"
          autoFocus
        />
      ) : (
        <PhoneNumberInput onPhoneNumberChange={onPhoneNumberChange} />
      )}
      <Button
        isLoading={isLoading}
        variant="solid"
        size="md"
        style={{
          width: 277,
          color: colors.lava,
          borderRadius: 4,
          backgroundColor: colors.white,
          marginTop: 20,
          height: 54,
        }}
        onClick={
          showCodeInput
            ? onVerifySmsCheckButtonPress
            : onStartVerifySmsButtonPress
        }
      >
        continue
      </Button>

      <Box
        display="flex"
        style={{
          color: colors.white,
          marginTop: 20,
          width: 277,
          textAlign: "center",
          fontSize: 14,
        }}
      >
        By tapping "continue", you agree to our Privacy Policy and Terms of
        Service.
      </Box>
    </Box>
  );
}
