export const APP_NAME = "shaka-web";

export const APP_VERSION = process.env.REACT_APP_GIT_SHA || "";

export const IsProduction = () =>
  process.env.NODE_ENV === "production" || false;

export const ProductionAppUrl = "https://app.heyshaka.com";

export const ProductLandingUrl = "https://heyshaka.com";

export const ProductionApiUrl = "https://api.heyshaka.com/query";
export const DevelopmentApiUrl = "http://localhost:3002/query";
// export const DevelopmentApiUrl = "https://api.heyshaka.com/query";

export const BugReportFormUrl =
  "https://form.nativeforms.com/U0RLVDWy1jZmsWYShHcv1Db";
