import React from "react";
import { Spinner } from "@chakra-ui/react";
import { Navigate, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

import colors from "../helpers/colors";
import PublicPageContainer from "./PublicPageContainer";
import WithQuery from "../components/WithQuery";
import { EVENT_INFO_PUBLIC_QUERY } from "../graphql/event_invites";
import EventInfoPublicCard from "../components/EventInfoPublicCard";

function PublicEventInfoInner({ data, error, loading }) {
  if (error) {
    console.log(error);
    Sentry.captureException(error);
  }

  let content = null;

  if (loading) {
    content = <Spinner color={colors.white} />;
  } else if (!!error) {
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
      />
    );
  } else if (data?.eventInfoPublic) {
    content = <EventInfoPublicCard eventInfoPublic={data?.eventInfoPublic} />;
  }

  return <PublicPageContainer>{content}</PublicPageContainer>;
}

export default function PublicEventInfo() {
  let { eventId } = useParams();

  return WithQuery(PublicEventInfoInner, EVENT_INFO_PUBLIC_QUERY, {
    eventId,
  })();
}
