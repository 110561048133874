import React from "react";
import { Box } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import Header from "../components/Header";
import WithQuery from "../components/WithQuery";
import { CURRENT_USER_QUERY } from "../graphql/users";

function AuthdPageContainerInner({ data, error, loading, children }) {
  if (error) {
    console.log(error);
    Sentry.captureException(error);
  }

  const currentUser = data?.currentUser;
  const childrenWithProps = React.Children.map(children, (child) => {
    if (currentUser && React.isValidElement(child)) {
      return React.cloneElement(child, { currentUser });
    }
    return child;
  });

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      background="linear-gradient(134.93deg, #FFC670 -5.44%, #FF4B40 48.38%)"
    >
      <Header />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex={1}
      >
        {childrenWithProps}
      </Box>
    </Box>
  );
}

export default function AuthdPageContainer({ children }) {
  return WithQuery(AuthdPageContainerInner, CURRENT_USER_QUERY, null, {
    children,
  })();
}
