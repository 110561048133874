import React from "react";
import { Flex, chakra } from "@chakra-ui/react";
import { FiAlertCircle } from "react-icons/fi";

import colors from "../helpers/colors";

const ErrorBox = ({ message }) => {
  return (
    <Flex marginTop={5} w="full" alignItems="center" justifyContent="center">
      <Flex
        maxW="md"
        mx="auto"
        bg="white"
        shadow="lg"
        rounded="lg"
        overflow="hidden"
        style={{ minWidth: 360, transition: "all 300ms ease-out" }}
        _hover={{
          transform: "scale(1.05)",
          transition: "all .2s ease-in-out",
        }}
        px={5}
        py={4}
        alignItems="center"
      >
        <FiAlertCircle size="3em" />

        <chakra.h1
          fontSize="md"
          fontWeight="md"
          color={colors.ink}
          textAlign="left"
          style={{
            marginLeft: 25,
          }}
        >
          {message?.length > 1
            ? message.charAt(0).toUpperCase() + message.slice(1)
            : message}
        </chakra.h1>
      </Flex>
    </Flex>
  );
};

export default ErrorBox;
