import React from "react";
import { Flex, Input, Button, useToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import {
  CURRENT_USER_QUERY,
  UPDATE_USER_PROFILE_MUTATION,
} from "../graphql/users";
import ShakaApolloClient from "../services/ShakaApolloClient";

export default function EditUserName({ currentUser }) {
  const [firstName, setFirstName] = React.useState(currentUser?.firstName);
  const [lastName, setLastName] = React.useState(currentUser?.lastName);

  const toast = useToast();

  const updateUserName = () => {
    if (!firstName || firstName === "" || !lastName || lastName === "") {
      return;
    }

    ShakaApolloClient.mutate({
      mutation: UPDATE_USER_PROFILE_MUTATION,
      variables: { firstName, lastName },
      refetchQueries: [CURRENT_USER_QUERY],
    })
      .then((r) => {
        toast({
          title: "salutations!",
          status: "success",
          duration: 3000,
        });
      })
      .catch((e) => {
        console.log("UPDATE_USER_PROFILE_MUTATION error:", e);
        Sentry.captureException(e);
      });
  };

  return (
    <Flex align="center" justify="center" flexDir={"column"}>
      <Flex>
        <Input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="first name"
          size="md"
          mr={3}
        />
        <Input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="last name"
          size="md"
        />
      </Flex>

      <Flex width={"100%"} mt={2}>
        <Button
          size="md"
          variant="solid"
          colorScheme="green"
          style={{
            flex: 1,
            width: "100%",
          }}
          disabled={
            !firstName || firstName === "" || !lastName || lastName === ""
          }
          onClick={updateUserName}
        >
          continue
        </Button>
      </Flex>
    </Flex>
  );
}
