import { gql } from "@apollo/client";

export const VERIFY_SMS_START_MUTATION = gql`
  mutation VerifySmsStart($phoneNumber: String!) {
    auth {
      verifySmsStart(phoneNumber: $phoneNumber)
    }
  }
`;

export const VERIFY_SMS_CHECK_MUTATION = gql`
  mutation VerifySmsCheck($phoneNumber: String!, $code: String!) {
    auth {
      verifySmsCheck(phoneNumber: $phoneNumber, code: $code)
    }
  }
`;
