import React, { useEffect } from "react";
import {
  Link,
  MenuButton,
  IconButton,
  MenuItem,
  MenuList,
  Menu,
  Box,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import {
  FiCompass,
  FiLogOut,
  FiMoreVertical,
  FiAlertCircle,
} from "react-icons/fi";

import { ProductLandingUrl, BugReportFormUrl } from "../helpers/config";
import colors from "../helpers/colors";
import { Logout, IsAuthd } from "../services/ShakaAuth";

export default function ShakaMenu() {
  const navigate = useNavigate();
  const [isAuthd, setIsAuthd] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const ia = await IsAuthd();
      setIsAuthd(ia);
    };
    fetchData();
  }, []);

  const onLogoutButtonPress = () => {
    Logout();
    navigate(`/login`);
  };

  return (
    <Menu>
      <Box
        as={motion.div}
        whileHover={{ opacity: 0.8 }}
        whileTap={{ opacity: 0.2 }}
        transition="0.1s linear"
      >
        <MenuButton
          as={IconButton}
          icon={<FiMoreVertical size="1.3em" />}
          variant="ghost"
          size="lg"
          color="white"
          style={{
            backgroundColor: "transparent",
            outline: "none !important",
            outlineWidth: "0 !important",
            boxShadow: "none",
          }}
        />
      </Box>
      <MenuList fontSize="sm" py={0} borderRadius={4}>
        <Link href={ProductLandingUrl} isExternal>
          <MenuItem icon={<FiCompass />}>heyshaka.com</MenuItem>
        </Link>

        <Link href={BugReportFormUrl} isExternal>
          <MenuItem icon={<FiAlertCircle color={colors.electricBlue} />}>
            help
          </MenuItem>
        </Link>

        {isAuthd ? (
          <MenuItem icon={<FiLogOut />} onClick={onLogoutButtonPress}>
            log out
          </MenuItem>
        ) : null}
      </MenuList>
    </Menu>
  );
}
