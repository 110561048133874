import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import EditUserName from "./EditUserName";
import colors from "../helpers/colors";

export default function OnboardUser({ eventInvite, currentUser }) {
  if (currentUser?.onboarded) {
    return null;
  }

  return (
    <Flex align="center" justify="center" flexDir={"column"}>
      <Text
        fontSize="md"
        mb={4}
        color={colors.white}
        fontWeight={600}
        className="animated-gradient"
        style={{
          width: "100%",
          textAlign: "center",
          padding: "12px 8px",
          borderRadius: "6px",
          marginBottom: 24,
        }}
      >
        one more thing - what's your name?
      </Text>

      <Flex alignItems="center" mb={2}>
        <EditUserName currentUser={currentUser} />
      </Flex>
    </Flex>
  );
}
