import React from "react";
import { Flex, ButtonGroup, Button, useToast, Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import {
  EVENT_INVITE_RESPOND_MUTATION,
  EVENT_INVITE_REPLY_STATUS_NO_REPLY,
  EVENT_INVITE_REPLY_STATUS_YES,
  EVENT_INVITE_REPLY_STATUS_NO,
  EVENT_INVITE_REPLY_STATUS_MAYBE,
} from "../graphql/event_invites";
import ShakaApolloClient from "../services/ShakaApolloClient";
import { ReplyStatusString, ReplyStatusColor } from "../helpers/strings";
import colors from "../helpers/colors";
import { EventHostNamesString } from "../helpers/strings";

export default function EventInviteReplyButtons({
  eventInviteId,
  replyStatus,
  hostName,
  coHostName,
}) {
  const [editing, setEditing] = React.useState(
    !replyStatus || replyStatus === EVENT_INVITE_REPLY_STATUS_NO_REPLY
  );

  const toast = useToast();

  const [replyStatusInner, setReplyStatusInner] = React.useState(replyStatus);

  const replyStatusSelected = (selectedReplyStatus) => {
    setReplyStatusInner(selectedReplyStatus);

    ShakaApolloClient.mutate({
      mutation: EVENT_INVITE_RESPOND_MUTATION,
      variables: { eventInviteId, replyStatus: selectedReplyStatus },
    })
      .then(() => {
        let toastText = null;
        switch (selectedReplyStatus) {
          case "yes":
            toastText = "see you there! 🤙";
            break;
          case "no":
            toastText = "✌️";
            break;

          case "maybe":
            toastText = "perhaps! 👻";
            break;

          default:
            toastText = "ack";
            break;
        }

        toast({
          title: toastText,
          status: "success",
          duration: 3000,
        });
      })
      .catch((e) => {
        console.log("EVENT_INVITE_RESPOND_MUTATION error:", e);
        Sentry.captureException(e);
      })
      .finally(() => {
        setEditing(false);
      });
  };

  if (!editing) {
    return (
      <Button
        size="md"
        variant="solid"
        style={{
          flex: 1,
          backgroundColor: ReplyStatusColor(replyStatus),
          color: colors.white,
        }}
        onClick={() => {
          setEditing(true);
        }}
      >
        you're {ReplyStatusString(replyStatus)}
      </Button>
    );
  }

  return (
    <Flex flex={1} flexDir="column" justifyContent="center" alignItems="center">
      <Text
        fontSize="md"
        mb={4}
        color={colors.white}
        fontWeight={600}
        className="animated-gradient"
        style={{
          width: "100%",
          textAlign: "center",
          borderRadius: "6px",
          padding: "12px 8px",
          marginBottom: 24,
        }}
      >
        let {EventHostNamesString(hostName, coHostName) || "the host"} know if
        you're attending!
      </Text>

      <ButtonGroup
        variant="outline"
        colorScheme="gray"
        isAttached
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Button
          isActive={replyStatusInner === EVENT_INVITE_REPLY_STATUS_YES}
          onClick={() => replyStatusSelected(EVENT_INVITE_REPLY_STATUS_YES)}
          style={{
            color: ReplyStatusColor(EVENT_INVITE_REPLY_STATUS_YES),
            flex: 1,
          }}
        >
          yes
        </Button>
        <Button
          isActive={replyStatusInner === EVENT_INVITE_REPLY_STATUS_NO}
          onClick={() => replyStatusSelected(EVENT_INVITE_REPLY_STATUS_NO)}
          style={{
            color: ReplyStatusColor(EVENT_INVITE_REPLY_STATUS_NO),
            flex: 1,
          }}
        >
          no
        </Button>
        <Button
          isActive={replyStatusInner === EVENT_INVITE_REPLY_STATUS_MAYBE}
          onClick={() => replyStatusSelected(EVENT_INVITE_REPLY_STATUS_MAYBE)}
          style={{
            color: ReplyStatusColor(EVENT_INVITE_REPLY_STATUS_MAYBE),
            flex: 1,
          }}
        >
          {ReplyStatusString(EVENT_INVITE_REPLY_STATUS_MAYBE)}
        </Button>
      </ButtonGroup>
    </Flex>
  );
}
