import { gql } from "@apollo/client";

export const dateFormat = "YYYY-MM-DD";
export const timeFormat = "HH:mm:00ZZ";

export const EVENT_INVITE_REPLY_STATUS_NO_REPLY = "no_reply";
export const EVENT_INVITE_REPLY_STATUS_YES = "yes";
export const EVENT_INVITE_REPLY_STATUS_NO = "no";
export const EVENT_INVITE_REPLY_STATUS_MAYBE = "maybe";

export const EVENT_INVITE_FRAGMENT = gql`
  fragment EventInviteFragment on EventInvite {
    id
    eventId

    publishStatus
    replyStatus

    eventInfo {
      title
      description

      status
      privacyType

      shortUrl

      startDate
      endDate
      startTime
      endTime

      coverImageUrl

      locationCustomName
      location {
        id
        googlePlaceId
        name
        formattedAddress
        latitude
        longitude
      }

      hostName
      coHostName
    }
  }
`;

export const EVENT_INVITE_FULL_EVENT_FRAGMENT = gql`
  fragment EventInviteFullEventFragment on EventInvite {
    id
    eventId

    publishStatus
    replyStatus

    eventInfo {
      title
      description

      status
      privacyType

      shortUrl

      startDate
      endDate
      startTime
      endTime

      coverImageUrl

      locationCustomName
      location {
        id
        googlePlaceId
        name
        formattedAddress
        latitude
        longitude
      }

      eventInfoGuests {
        name
        replyStatus
      }

      hostName
      coHostName
    }
  }
`;

export const EVENT_INFO_PUBLIC_FRAGMNENT = gql`
  fragment EventInfoPublicFragment on EventInfoPublic {
    id

    title

    hostName
    coHostName

    coverImageUrl

    startDate
  }
`;

export const EVENT_INFO_PUBLIC_QUERY = gql`
  ${EVENT_INFO_PUBLIC_FRAGMNENT}
  query EventInfoPublic($eventId: ID!) {
    eventInfoPublic(eventId: $eventId) {
      ...EventInfoPublicFragment
    }
  }
`;

export const EVENT_INVITES_QUERY = gql`
  ${EVENT_INVITE_FRAGMENT}
  query EventInvites {
    eventInvites {
      ...EventInviteFragment
    }
  }
`;

export const EVENT_INVITE_FOR_EVENT_QUERY = gql`
  ${EVENT_INVITE_FULL_EVENT_FRAGMENT}
  query EventInviteForEvent($eventId: ID!) {
    eventInviteForEvent(eventId: $eventId) {
      ...EventInviteFullEventFragment
    }
  }
`;

export const EVENT_INVITE_RESPOND_MUTATION = gql`
  mutation RespondEventInvite($eventInviteId: ID!, $replyStatus: String!) {
    respondEventInvite(
      eventInviteId: $eventInviteId
      replyStatus: $replyStatus
    ) {
      id
      replyStatus
    }
  }
`;
