import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ShakaApolloClient from "./services/ShakaApolloClient";
import { IsProduction, APP_VERSION } from "./helpers/config";

import PrivateRoute from "./pages/PrivateRoute";
import AuthPage from "./pages/AuthPage";
import DashboardPage from "./pages/DashboardPage";
import EventInvitePage from "./pages/EventInvitePage";
import PublicEventInfo from "./pages/PublicEventInfo";
import { metaDefault } from "./helpers/metadata";

Sentry.init({
  dsn: "https://4b120b22aa2d4e48ae244ccde50b3def@o121278.ingest.sentry.io/6531944",
  release: APP_VERSION,
  environment: IsProduction() ? "production" : "development",
  autoSessionTracking: true,
  enabled: IsProduction(), // turn off alerts in development

  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const theme = {
  colors: {
    green: {
      50: "#EBFBE9",
      100: "#C7F4C3",
      200: "#A3ED9C",
      300: "#7FE675",
      400: "#5ADF4E",
      500: "#36D827",
      600: "#2BAD1F",
      700: "#218118",
      800: "#165610",
      900: "#0B2B08",
    },
    yellow: {
      50: "#FFF5E5",
      100: "#FFE3B8",
      200: "#FFD08A",
      300: "#FFBE5C",
      400: "#FFAC2E",
      500: "#FF9900",
      600: "#CC7B00",
      700: "#995C00",
      800: "#663D00",
      900: "#331F00",
    },
    blue: {
      50: "#E5ECFF",
      100: "#B8C9FF",
      200: "#8AA7FF",
      300: "#5C85FF",
      400: "#2E62FF",
      500: "#0040FF",
      600: "#0033CC",
      700: "#002699",
      800: "#001966",
      900: "#000D33",
    },
    cyan: {
      50: "#E5FCFF",
      100: "#B8F6FF",
      200: "#8AF1FF",
      300: "#5CEBFF",
      400: "#2EE6FF",
      500: "#00E0FF",
      600: "#00B3CC",
      700: "#008799",
      800: "#005A66",
      900: "#002D33",
    },
    red: {
      50: "#FFE5EB",
      100: "#FFB8C6",
      200: "#FF8AA1",
      300: "#FF5C7C",
      400: "#FF2E58",
      500: "#FF0033",
      600: "#CC0029",
      700: "#99001F",
      800: "#660014",
      900: "#33000A",
    },
    orange: {
      50: "#FFE7E5",
      100: "#FFBCB8",
      200: "#FF908A",
      300: "#FF655C",
      400: "#FF3A2E",
      500: "#FF0F00",
      600: "#CC0C00",
      700: "#990900",
      800: "#660600",
      900: "#330300",
    },
    teal: {
      50: "#EAFAFA",
      100: "#C5F2F1",
      200: "#9FEAE7",
      300: "#7AE1DE",
      400: "#54D9D5",
      500: "#2FD0CC",
      600: "#25A7A3",
      700: "#1C7D7A",
      800: "#135352",
      900: "#092A29",
    },
    purple: {
      50: "#ECE9FC",
      100: "#CBC0F6",
      200: "#A998F1",
      300: "#876FEB",
      400: "#6647E6",
      500: "#441FE0",
      600: "#3618B4",
      700: "#291287",
      800: "#1B0C5A",
      900: "#0E062D",
    },
    pink: {
      50: "#FFE5F7",
      100: "#FFB8E8",
      200: "#FF8AD9",
      300: "#FF5CCA",
      400: "#FF2EBB",
      500: "#FF00AC",
      600: "#CC008A",
      700: "#990067",
      800: "#660045",
      900: "#330022",
    },
  },
};

const shakaTheme = extendTheme(theme);

function App() {
  return (
    <HelmetProvider>
      <ApolloProvider client={ShakaApolloClient}>
        <BrowserRouter>
          <ChakraProvider theme={shakaTheme}>
            <Helmet>
              <title>{metaDefault.title}</title>
              <meta name="description" content={metaDefault.description} />
            </Helmet>
            <Routes>
              {/* Private routes */}
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/e/:eventId" element={<EventInvitePage />} />
              </Route>

              {/* Public routes */}
              <Route path="/login" element={<AuthPage />} />
              <Route path="/ep/:eventId" element={<PublicEventInfo />} />
            </Routes>
          </ChakraProvider>
        </BrowserRouter>
      </ApolloProvider>
    </HelmetProvider>
  );
}

export default App;
