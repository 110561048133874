import earlymorningimg from "../assets/eventBackgrounds/earlymorning.png";

export const metaDefault = {
  title: "Shaka!",
  description: "Shaka is fastest way to make plans with your friends.",
};

export const coverDefault = earlymorningimg;

export const EventHostNamesString = (hostName, coHostName, suffix) => {
  if (!hostName || hostName === "") {
    return "";
  }
  let returnString = `${hostName}`;

  if (coHostName && coHostName !== "") {
    returnString += ` & ${coHostName}`;
  }

  if (suffix && suffix !== "") {
    returnString += ` ${suffix}`;
  }

  return returnString;
};

export const getEventMetaTitle = (eventTitle, hostName, coHostName) => {
  const hostNamesString = EventHostNamesString(
    hostName,
    coHostName,
    "invited you to "
  );

  return `${hostNamesString ? hostNamesString : "Invite to "}${
    eventTitle || "an event"
  }`;
};
