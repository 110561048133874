import React from "react";
import { Box, Flex, chakra } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import earlymorningImage from "../assets/eventBackgrounds/earlymorning.png";
import {
  EventTimeString,
  ReplyStatusString,
  ReplyStatusColor,
} from "../helpers/strings";
import colors from "../helpers/colors";
import { EventHostNamesString } from "../helpers/strings";

const EventInviteCardMini = ({ eventInvite }) => {
  const eventTime = EventTimeString(
    eventInvite?.eventInfo?.startDate,
    eventInvite?.eventInfo?.startTime
  );

  return (
    <Flex marginBottom={5} w="full" alignItems="center" justifyContent="center">
      <Link to={`/e/${eventInvite.eventId}`}>
        <Flex
          maxW="md"
          mx="auto"
          bg="white"
          shadow="lg"
          rounded="lg"
          overflow="hidden"
          style={{
            minWidth: 360,
            maxWidth: 360,
            transition: "all 300ms ease-out",
            maxHeight: 141,
            minHeight: 141,
          }}
          _hover={{
            transform: "scale(1.05)",
            transition: "all .2s ease-in-out",
          }}
        >
          <Box
            w={0.33}
            bgSize="cover"
            style={{
              backgroundImage: eventInvite?.eventInfo?.coverImageUrl
                ? `url(${eventInvite?.eventInfo?.coverImageUrl})`
                : `url(${earlymorningImage})`,
            }}
          ></Box>

          <Flex
            w={2 / 3}
            px={{ base: 4 }}
            py={{ base: 3 }}
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <chakra.h2 fontSize="20px" fontWeight="bold" color={colors.ink}>
                {eventInvite?.eventInfo?.title}
              </chakra.h2>

              {eventTime && eventTime.length ? (
                <chakra.p
                  mt={1.5}
                  fontSize="xs"
                  fontWeight="400"
                  color={colors.greyDark}
                  style={{ minWidth: 250, minHeight: 22 }}
                >
                  {eventTime}
                </chakra.p>
              ) : null}
            </Box>

            <Flex alignItems="flex-end" justifyContent="space-between">
              <chakra.button
                px={0}
                py={1}
                fontSize="sm"
                color={colors.ink}
                fontWeight="500"
                textAlign="left"
              >
                {EventHostNamesString(
                  eventInvite?.eventInfo?.hostName,
                  eventInvite?.eventInfo?.coHostName,
                  "invited you"
                )}
              </chakra.button>
              <chakra.button
                px={2}
                py={1}
                bg={ReplyStatusColor(eventInvite?.replyStatus)}
                fontSize="xs"
                color={colors.white}
                fontWeight="bold"
                rounded="lg"
              >
                {ReplyStatusString(eventInvite?.replyStatus)}
              </chakra.button>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  );
};

export default EventInviteCardMini;
