import React from "react";
import { Spinner } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

import colors from "../helpers/colors";
import AuthdPageContainer from "./AuthdPageContainer";
import WithQuery from "../components/WithQuery";
import { EVENT_INVITE_FOR_EVENT_QUERY } from "../graphql/event_invites";
import EventInviteCardFull from "../components/EventInviteCardFull";
import ErrorBox from "../components/ErrorBox";

function EventInvitePageInner({ currentUser, data, error, loading }) {
  if (error) {
    console.log(error);
    Sentry.captureException(error);
  }

  let content = null;

  if (loading) {
    content = <Spinner color={colors.white} />;
  } else if (!!error) {
    content = <ErrorBox message={error.message} />;
  } else if (data?.eventInviteForEvent) {
    content = (
      <EventInviteCardFull
        eventInvite={data?.eventInviteForEvent}
        currentUser={currentUser}
      />
    );
  }

  return <AuthdPageContainer>{content}</AuthdPageContainer>;
}

export default function EventInvitePage() {
  let { eventId } = useParams();

  return WithQuery(EventInvitePageInner, EVENT_INVITE_FOR_EVENT_QUERY, {
    eventId,
  })();
}
