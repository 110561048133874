import React from "react";
import { Image, Box, Flex, chakra } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import logo from "../assets/Shaka.png";
import ShakaMenu from "./ShakaMenu";

export default function Header() {
  return (
    <Box pos="relative">
      <chakra.header transition="box-shadow 0.2s" w="full" overflowY="hidden">
        <chakra.div
          h="4.5rem"
          mx="auto"
          maxW="1200px"
          style={{ paddingTop: 10 }}
        >
          <Flex w="full" h="full" px="6" align="center" justify="space-between">
            <Flex align="center">
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.1s linear"
              >
                <Link to="/">
                  <Image boxSize="200px" objectFit="contain" src={logo} />
                </Link>
              </Box>
            </Flex>

            <Flex justify="flex-end" w="full">
              <ShakaMenu />
            </Flex>
          </Flex>
        </chakra.div>
      </chakra.header>
    </Box>
  );
}
