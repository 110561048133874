import { gql } from "@apollo/client";

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUserFragment on User {
    id
    firstName
    lastName
    email
    primaryPhone
    onboarded
  }
`;

export const CURRENT_USER_QUERY = gql`
  ${CURRENT_USER_FRAGMENT}
  {
    currentUser {
      ...CurrentUserFragment
    }
  }
`;

export const UPDATE_USER_PROFILE_MUTATION = gql`
  ${CURRENT_USER_FRAGMENT}
  mutation UpdateUserProfile($firstName: String!, $lastName: String!) {
    updateUserProfile(input: { firstName: $firstName, lastName: $lastName }) {
      ...CurrentUserFragment
    }
  }
`;
