import React from "react";
import { Box, Flex, chakra, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import EventInviteCardHeader from "./EventInviteCardHeader";
import colors from "../helpers/colors";
import { EventTimeString } from "../helpers/strings";
import EventInviteCardHeaderGuestList from "./EventInviteCardHeaderGuestList";

const fillerGuestList = [
  {
    name: "Randy Randalman",
    replyStatus: "yes",
  },
  {
    name: "Finn O’Fish",
    replyStatus: "yes",
  },
  {
    name: "Jane Longshore",
    replyStatus: "yes",
  },
  {
    name: "Roger Starky",
    replyStatus: "yes",
  },
  {
    name: "Rascal Jimmy",
    replyStatus: "maybe",
  },
];

const EventInfoPublicCard = ({ eventInfoPublic }) => {
  return (
    <Flex p={4} w="full" alignItems="center" justifyContent="center">
      <Box mx="auto" rounded="lg" shadow="md" bg="white" w="full" maxW="lg">
        <EventInviteCardHeader
          title={eventInfoPublic?.title}
          coverImageUrl={eventInfoPublic?.coverImageUrl}
          leftText={EventTimeString(eventInfoPublic?.startDate, null)}
          hostName={eventInfoPublic?.hostName}
          coHostName={eventInfoPublic?.coHostName}
        />
        <div style={{ filter: `blur(4px)` }} className="unselectable-text">
          <EventInviteCardHeaderGuestList guestList={fillerGuestList} />
        </div>
        <Box>
          <chakra.p
            fontSize="sm"
            className="unselectable-text"
            color={colors.ink}
            p={8}
            style={{
              marginTop: 5,
              fontWeight: 300,
              whiteSpace: "pre-wrap",
              overflowY: "scroll",
              filter: `blur(4px)`,
            }}
            _disabled={true}
          >
            Hey friends! You have to sign in to see the secret deets. Privacy is
            priceless amirite?!
          </chakra.p>
        </Box>
        <Box mt={3} pb={4}>
          <Flex
            align="center"
            justify="center"
            flexDir={"column"}
            pr={6}
            pl={6}
          >
            <Link
              to="/login"
              style={{
                width: "100%",
              }}
            >
              <Text
                fontSize="md"
                mb={4}
                color={colors.white}
                fontWeight={600}
                className="animated-gradient"
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "8px",
                  borderRadius: "6px",
                }}
              >
                sign in to see everything &amp; reply
              </Text>
            </Link>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default EventInfoPublicCard;
