class ShakaLocalStorage {
  static async save({ key, data }) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  static async load({ key }) {
    return JSON.parse(localStorage.getItem(key));
  }

  static async remove({ key }) {
    localStorage.removeItem(key);
  }
}

export default ShakaLocalStorage;
