import React from "react";
import { Spinner, Flex } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import colors from "../helpers/colors";
import AuthdPageContainer from "./AuthdPageContainer";
import WithQuery from "../components/WithQuery";
import { EVENT_INVITES_QUERY } from "../graphql/event_invites";
import EventInviteCardMini from "../components/EventInviteCardMini";
import WaitlistFooter from "../components/WaitlistFooter";

const renderEventInvites = (eventInvites) => {
  return eventInvites?.map((eventInvite) => {
    return (
      <EventInviteCardMini key={eventInvite.id} eventInvite={eventInvite} />
    );
  });
};

function DashboardPageInner({ data, error, loading }) {
  if (error) {
    console.log(error);
    Sentry.captureException(error);
  }

  let content = null;

  if (loading) {
    content = <Spinner color={colors.white} />;
  } else if (data?.eventInvites?.length !== 0) {
    content = (
      <Flex
        p={4}
        w="full"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {renderEventInvites(data?.eventInvites)}
      </Flex>
    );
  }

  return (
    <AuthdPageContainer>
      {content}
      <WaitlistFooter />
    </AuthdPageContainer>
  );
}

export default function DashboardPage() {
  return WithQuery(DashboardPageInner, EVENT_INVITES_QUERY)();
}
