import * as Sentry from "@sentry/react";

import ShakaLocalStorage from "./ShakaLocalStorage";
// eslint-disable-next-line import/no-cycle
import ShakaApolloClient from "./ShakaApolloClient";
import {
  VERIFY_SMS_START_MUTATION,
  VERIFY_SMS_CHECK_MUTATION,
} from "../graphql/auth";
import { LoginErrorMsg, LoginCodeErrorMsg } from "../helpers/strings";

const CURRENT_USER_KEY = "currentUser";

export const REDIRECT_AFTER_LOGIN_KEY = "redirectPathAfterLogin";

export async function VerifySmsStart({ phoneNumber }) {
  return ShakaApolloClient.mutate({
    mutation: VERIFY_SMS_START_MUTATION,
    variables: { phoneNumber },
  })
    .then((r) => {
      const success = r?.data?.auth?.verifySmsStart?.success;

      if (!success) {
        throw new Error(LoginErrorMsg);
      }
    })
    .catch((e) => {
      console.log("Login Error:", e);
      Sentry.captureException(e);
      throw new Error(e);
    });
}

export async function VerifySmsCheck({ phoneNumber, code }) {
  return ShakaApolloClient.mutate({
    mutation: VERIFY_SMS_CHECK_MUTATION,
    variables: { phoneNumber, code },
  })
    .then((r) => {
      const token = r?.data?.auth?.verifySmsCheck?.token;
      const userId = r?.data?.auth?.verifySmsCheck?.userId;

      // Set localstorage data
      if (token && userId) {
        // Chain the localstorage promise
        return ShakaLocalStorage.save({
          key: CURRENT_USER_KEY,
          data: {
            token,
            userId,
          },
        });
      }
      console.log("VerifySmsCheck response userid or token is null");
      throw new Error(LoginCodeErrorMsg);
    })
    .catch((e) => {
      console.log("Login Error:", e);
      Sentry.captureException(e);
      throw new Error(LoginCodeErrorMsg);
    });
}

export const Logout = () =>
  // Clear localstorage token
  ShakaLocalStorage.remove({
    key: CURRENT_USER_KEY,
  })
    .then(() =>
      // Clear apollo state, return chained promise
      ShakaApolloClient.resetStore()
    )
    .catch((err) => {
      console.log("Error in ShakaAuth.Logout", err);
      Sentry.captureException(err);
      throw err;
    });

export async function GetLocalAuthData() {
  let localAuthData = null;

  await ShakaLocalStorage.load({
    key: CURRENT_USER_KEY,
  })
    .then((ret) => {
      if (!ret) {
        return null;
      }

      localAuthData = {};
      localAuthData.token = ret?.token;
      localAuthData.userId = ret?.userId;
    })
    .catch((err) => {
      console.log(err.message);
      Sentry.captureException(err);
    });

  return localAuthData;
}

export async function GetLocalAuthToken() {
  const localAuthData = await GetLocalAuthData();
  return localAuthData ? localAuthData?.token : null;
}

export async function IsAuthd() {
  const token = await GetLocalAuthToken();
  return !!token;
}
